<template>
  <DashboardTemplate>
    <el-row
      type="flex"
      justify="center"
      class="mg-y-3"
      v-loading.fullscreen.lock="fullLoading"
      element-loading-background="rgba(255, 255, 255, 0.8)"
    >
      <el-col :span="18" class="is-flex flex-fill js-center">
        <div>
          <div class="box-image">
            <avatar
              :username="user.name"
              :rounded="false"
              :src="`${
                user.profileImg != 'undefined' && user.profileImg
                  ? imgUrl + user.profileImg
                  : ''
              }`"
              :size="60"
            >
            </avatar>
          </div>
        </div>
        <div class="pd-l-6">
          <h4 class="mg-b-7 mg-t-0">{{ user.name }} {{ user.surname }}</h4>
          <p class="text-grey mg-y-7">{{ user.email }}</p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="border-bottom pd-y-5">
          <router-link to="/edit-profile" class="text-dark">
            แก้ไขข้อมูลส่วนตัว
          </router-link>
        </div>
        <div class="border-bottom pd-y-5">
          <router-link to="/edit-company" class="text-dark">
            แก้ไขข้อมูลบริษัท
          </router-link>
        </div>
        <div class="border-bottom pd-y-5" v-if="user.role == 0">
          <router-link to="/edit-privacy" class="text-dark">
            จัดการข้อมูลส่วนบุคคล
          </router-link>
        </div>
        <div class="border-bottom pd-y-5">
          <router-link to="/change-password" class="text-dark">
            แก้ไขรหัสผ่าน
          </router-link>
        </div>
        <div class="border-bottom pd-y-5">
          <router-link to="/reset-assessment" class="text-dark">
            ประเมินความพร้อมใหม่
          </router-link>
        </div>
        <!-- <div class="border-bottom pd-y-5">
          <router-link to="/feedback" class="text-dark">
            ประเมินความพึงพอใจของระบบ
          </router-link>
        </div> -->
        <div @click="signout" class="border-bottom pd-y-5 cr-pointer">
          ออกจากระบบ
        </div>
      </el-col>
    </el-row>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
import Avatar from "vue-avatar";

export default {
  data() {
    return {
      fullLoading: false,
      imgUrl: process.env.VUE_APP_IMAGE_URL
    };
  },
  components: {
    DashboardTemplate,
    Avatar
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    open() {
      this.$confirm("คุณต้องการยืนยันการประเมินใหม่?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "Delete completed"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    signout() {
      this.fullLoading = true;
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      // this.$store.commit("SIGNOUT");
      window.localStorage.removeItem("eram");
      delete HTTP.defaults.headers.common.Authorization;
      //this.$router.push("/");
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    }
  }
};
</script>
